import { forwardRef } from "react";

const TextArea = forwardRef<
    HTMLTextAreaElement,
    React.TextareaHTMLAttributes<HTMLTextAreaElement>
>(function TextArea({ className, ...rest }, ref) {
    return (
        <textarea
            ref={ref}
            className={`rounded-md shadow-sm border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 ${
                className ?? ""
            }`}
            {...rest}
        />
    );
});

export default TextArea;
